import React, {Suspense} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {img} from './assets/img/index';
import routes from './routes';
import PrivateRoute from './common/PrivateRoute';
import PublicRoute from './common/PublicRoute';
import NotFound from './common/NotFound';

const loading = () => (
  <div className="App-header">
    <img src={img.logoNabati} alt="logo"/>
  </div>
);

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={loading()}>
        <Switch>
          {
            routes.map((route, idx) => {
              return(
                  route.restricted !== false ?
                  <PrivateRoute component={route.component} path={`${route.path}`} exact={route.exact} key={idx}/>
                  :
                  <PublicRoute restricted={route.restricted} component={route.component} path={`${route.path}`} exact={route.exact} key={idx}/>
                ) 
            })
          }
            <Route component={NotFound}/>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;