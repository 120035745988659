import React from "react";
import {Route, Redirect} from "react-router-dom";
import {isLogin} from "../utils";

const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        // show the component only when the users is logged in
        // otherwise, redirect the user to signin page
        <Route 
        {...rest} 
        render={props => (
            isLogin() ?
            <Component {...props}/>
            :
            <Redirect to="/login"/>
        )}
        />
    );
};

export default PrivateRoute;