/**
 * risyandi - 2021
 * general utils
 * - remove leading zero
 * - convert date
 * - format date local ID
 * - convert timestamp to date
 * - group object by id
 * - greetings words
 * - wrap text
 * */

export const removeLeadingZero = (num) => {
  let numLeadingZero = num;
  while (numLeadingZero.charAt(0) === "0") {
    numLeadingZero = numLeadingZero.substring(1);
  }
  return numLeadingZero;
};

export const convertDate = (data) => {
  let result = data.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3$1$2");
  return result;
};

export const convertDateExp = (data) => {
  let result = data.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3$2$1");
  return result;
};

export const convertDateComa = (data) => {
  let result = data.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3,$2,$1");
  return result;
};

export const dateComaToEpoch = (data) => {
  let dataRes = data.toString();
  const date = new Date(dataRes);
  return date.getTime();
};

export const epochToDate = (data) => {
  const date = new Date(data);
  return date.toLocaleDateString();
};

export const formatDateId = (date) => {
  const timestamp = Date.parse(date);

  if (isNaN(timestamp) !== false) {
    return date
  }

  const dateObj = new Date(date);
  return new Intl.DateTimeFormat(["ban", "id"], {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  }).format(dateObj);
};

export const timestampToDate = (epoch) => {
  epoch = Number(epoch);
  const date = new Date(epoch);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${day}/${month}/${year}`;
};

export const groupByObjectByid = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    if (!map.has(key)) {
      map.set(key, [item]);
    } else {
      map.get(key).push(item);
    }
  });
  return map;
};

export const generateNumber = (event) => {
  let code = "";
  let possible = "0123456789";

  for (let index = 0; index < 10; index++) {
    code += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  let result = "MR" + code;
  return result;
};

export const generateNumberInc = (numId) => {
  let maxDigit = 8;
  let numIdString = numId !== null ? numId.toString() : "1";
  let lengthIdString = numIdString.length;
  let totalLengthDigit = maxDigit - lengthIdString;
  let code = "";
  for (let index = 0; index < totalLengthDigit; index++) {
    code += "0";
  }
  let result = "MR" + code + numIdString;
  return result;
};

export const checkRemainingText = (text) => {
  let maxLength = 500;
  if (maxLength === undefined) {
    maxLength = text.length;
  }
  const remaining = maxLength - text.length;
  if (remaining <= 0) {
    return "0";
  }
  return remaining;
};

export const greetingsWords = (params) => {
  let dateTime = new Date();
  let hours = dateTime.getHours();
  let greetings;

  if (hours < 12) {
    greetings = "Good Morning";
    return greetings;
  } else if (hours >= 12 && hours <= 17) {
    greetings = "Good Afternoon";
    return greetings;
  } else if (hours >= 17 && hours <= 24) {
    greetings = "Good Evening";
    return greetings;
  }
};

export const wrapText = (text) => {
  let maxLength = 50;
  let valuefinalDesc = text.substring(0, maxLength);
  valuefinalDesc = valuefinalDesc + "...";
  return valuefinalDesc;
};

export const getYearFromUtcDate = (date) => {
  const dateObj = new Date(date);
  return dateObj.getFullYear();
};

export const formatNumber = (value = 0) => {
  let result;
  value = parseFloat(value).toString().split(".");
  const num = Math.round(value[0]);

  if (Number.isInteger(num)) {
    const reverse = num.toString().split("").reverse().join("");
    result = reverse.match(/\d{1,3}/g);
    result = result.join(".").split("").reverse().join("");
  }
  if (value[1])
    value[1] = parseFloat(`0.${value[1]}`).toFixed(5).toString().split(".")[1];

  result = value[1] ? [result, value[1]].join(",") : result;

  return result;
};

export const formatToRupiah = (value) => {
  if (!value) return;
  if (value.toString().includes("Rp")) return value;
  return `Rp ${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
};

export const formatNumber2 = (number) => {
  return (+number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
