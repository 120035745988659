import React from "react";
import {Route, Redirect} from "react-router-dom";
import {isLogin} from "../utils";

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    return (
        // show the component for public users not logged in for access
        <Route
        {...rest} render={props => (
            isLogin() && restricted ?
            <Redirect to="/" />
            :
            <Component {...props}/>
        )}
        />
    );
};

export default PublicRoute;