export const getSelectList = (datas, fieldLabel, fieldValue, pushAllField = false) => {
    const selectList = datas.map(row => {
        if (!row) 
        // eslint-disable-next-line
        return
        const allField = pushAllField ? row : {}
        return {
            ...allField,
            label: row[fieldLabel],
            value: row[fieldValue],
        }
    })

    return selectList
}