/**
 * created by : Risyandi
 * 2021
 */

import logoReact from '../img/logo.svg';
import logoNabati from '../img/nabati-logo.png';
import logoEdotNabati from '../img/logo_edot_nabati.jpg';

const img = {
    logoReact,
    logoNabati,
    logoEdotNabati,
}

export {
    img
}