import React from 'react';
import {Link} from 'react-router-dom';
import {img} from '../assets/img/index';

const NotFound = (props) => {
    return (
    <div className="App">
        <header className="App-header text-center">
            <img src={img.logoNabati} alt="nabati-logo" />
            <h1> 404</h1>
            <h1> Oops! Page Not Found</h1>
            <Link to="/"> Back To Homepage</Link>
        </header>
    </div>
    )
}

export default NotFound;